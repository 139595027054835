import { drupal } from "@/lib/drupal/drupal"
import { DrupalNodeRelease, DrupalTaxonomyTermProduct, DrupalTaxonomyTerm } from "@/types"
import { addDays, addMonths, addQuarters, addYears, endOfMonth, endOfQuarter, endOfYear, format, startOfDay, startOfMonth, startOfQuarter, startOfYear } from "date-fns"
import { Fragment } from "react"
import { Body } from "../wysiwyg/Body"
import { searchParamsCache } from "@/lib/searchParams"
import { ReleaseNotesFilters } from "./ReleaseNotes.client"
import { DrupalJsonApiParams } from "drupal-jsonapi-params"
import Link from "next/link"

function FilterRange (label: string, startDate?: Date, endDate?: Date) {
  return {
    label,
    startDate: startDate ? startOfDay(startDate) : undefined,
    endDate: endDate ? startOfDay(endDate) : undefined
  }
}

export const ServerReleaseNotes = async ({ product, style = 'modern'}: {
  product: DrupalTaxonomyTermProduct,
  style?: 'traditional' | 'modern' | 'list' | 'sidebar-list'
}) => {
  if (style == "list") {
    return <ReleaseNoteList releases={await getReleases(product.id, undefined, undefined, undefined, 30)} />
  }
  if (style == "sidebar-list") {
    return <ReleaseNoteSidebarList releases={await getReleases(product.id, undefined, undefined, undefined, 30)} />
  }
  const firstRelease = await getEarliestRelease(product.id)

  const { ranges } = getDatePresents(firstRelease)
  // const rnDate = searchParamsCache.get('rnDate')
  const { rnDate, rnComponent } = searchParamsCache.all()

  const rangeKey = (Object.keys(ranges).includes(rnDate) ? rnDate : 'recent') as keyof typeof ranges

  const releases = await getReleases(
    product.id, 
    ranges[rangeKey].endDate ? format(ranges[rangeKey].endDate as Date, 'yyyy-MM-dd') : undefined, 
    ranges[rangeKey].startDate ? format(ranges[rangeKey].startDate as Date, 'yyyy-MM-dd') : undefined,
    rnComponent ?? undefined
  )

  const components = await getComponents(
    product.id, 
    ranges[rangeKey].endDate ? format(ranges[rangeKey].endDate as Date, 'yyyy-MM-dd') : undefined, 
    ranges[rangeKey].startDate ? format(ranges[rangeKey].startDate as Date, 'yyyy-MM-dd') : undefined,
  )
  return <>
    <ReleaseNotesFilters components={components} ranges={ranges}>
    { style == 'traditional' && <ReleaseNoteTraditional releases={releases} />}
    { style == 'modern' && <ReleaseNoteModern releases={releases} />}
    </ReleaseNotesFilters>
  </>
}

export const ReleaseNoteModern = ({ releases }: {
  releases: DrupalNodeRelease[]
}) => {
  return <div className="grid grid-cols-4 gap-2 my-4 place-items-stretch">
    {releases.map(release => (
        <Link key={release.id} className="bg-white border border-blue-600 p-4 rounded block text-center hover:bg-gray-400 hover:text-navy-600" href={release.path.alias}>{release.title}</Link>
    ))}
  </div>
}


export const ReleaseNoteTraditional = ({ releases }: {
  releases: DrupalNodeRelease[]
}) => {
  return <>{releases.map(release => (
    <Fragment key={release.id}>
      <h2 id={'rn-' + release.id}>{release.title}</h2>
      <Body value={release.body?.processed ?? release.body?.value} />
    </Fragment>
  ))}
  </>
}

export const ReleaseNoteList = ({ releases }: {
  releases: DrupalNodeRelease[]
}) => {
  return <ul className="list-none flex flex-col gap-4">{releases.map(release => (
    <li key={release.id}>
      <Link className="flex flex-col bg-white border border-blue-600 p-4 rounded block hover:bg-gray-400 hover:text-navy-600 no-underline" href={release.path.alias}>
        <span className="font-medium font-display">{release.title}</span>
        <span className="text-xs text-gray-700">{format(new Date(release.field_release_date), 'dd LLLL, yyyy')}</span>
      </Link>
    </li>
  ))}
  </ul>
}

export const ReleaseNoteSidebarList = ({ releases }: {
  releases: DrupalNodeRelease[]
}) => {
  return <>
    <h2 className="font-medium text-md mb-2 pb-2 border-b border-gray-600">Release announcements</h2>
    <ul className="list-none flex flex-col gap-2">{releases.map(release => (
      <li key={release.id}>
        <Link className="flex flex-col" href={release.path.alias}>
          <span className="">{release.title}</span>
          <span className="text-xs text-gray-700">{format(new Date(release.field_release_date), 'dd LLLL, yyyy')}</span>
        </Link>
      </li>
    ))}
    </ul>
  </>
}

export function getDatePresents(firstRelease: DrupalNodeRelease) {
  const date = startOfDay(new Date)
  const firstYear = startOfYear(new Date(firstRelease.field_release_date))
  const ranges:Record<string, ReturnType<typeof FilterRange>> = {
    recent: FilterRange('Recent', addDays(date, -90), addDays(date, 30)),
    last90days: FilterRange('Last 90 days', addDays(date, -90), date),
    lastMonth: FilterRange('Last month',  startOfMonth(addMonths(date, -1)),  endOfMonth(addMonths(date, -1))),
    last3Months: FilterRange('Last 3 months', startOfMonth(addMonths(date, -3)), endOfMonth(addMonths(date, -1))),
    thisQuarter: FilterRange('This quarter', startOfQuarter(date), endOfQuarter(date)),
    lastQuarter: FilterRange('Last quarter', startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))),
    thisYear: FilterRange('This year', startOfYear(date), endOfYear(date)),
    lastYear: FilterRange('Last year', startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))),
  }

  for (let i = startOfYear(addYears(date, -2)); i > firstYear ;i = startOfYear(addYears(i, -1))) {
    const label = format(i, 'yyyy');
    ranges['year' + format(i, 'yyyy')] = FilterRange(label, i, endOfYear(i))
  }

  return {date, ranges}
}

async function getComponents(productId: string, before?: string, since?: string) {
  const params = new DrupalJsonApiParams({
    'fields[node--article]': 'path,field_release_date,body,field_tags',
    'filter[field_product.id]': productId,
    'filter[status]': 1,
    'sort': '-field_release_date',
  })

  params.addInclude(['field_components'])

  if (before && before.match(/^\d{4}-\d{2}-\d{2}$/)) {
    params.addFilter('field_release_date', before, '<');
  }
  if (since && since.match(/^\d{4}-\d{2}-\d{2}$/)) {
    params.addFilter('field_release_date', since, '>');
  }

  const releases = await drupal.getResourceCollection<DrupalNodeRelease[]>('node--release', {params: params.getQueryObject()});
  const components = releases.reduce((components, release) => {
    const keys = components.map(c => c.id)
    const new_components = release.field_components.filter(c => !keys.includes(c.id))
    components.push(...new_components)
    return components
  }, [] as DrupalTaxonomyTerm[])
  components.sort((a, b) => a.name > b.name ? 1 : -1)
  return components
}

async function getReleases(productId: string, before?: string, since?: string, component?: string, limit?: number) {
  const params = new DrupalJsonApiParams({
    'fields[node--article]': 'path,field_release_date,body,field_tags',
    'filter[field_product.id]': productId,
    'filter[status]': 1,
    'sort': '-field_release_date',
  })

  if (component) {
    params.addFilter('field_components.id', component)
  }
  if (before && before.match(/^\d{4}-\d{2}-\d{2}$/)) {
    params.addFilter('field_release_date', before, '<');
  }
  if (since && since.match(/^\d{4}-\d{2}-\d{2}$/)) {
    params.addFilter('field_release_date', since, '>');
  }
  if (limit) {
    params.addPageLimit(limit)
  }

  return await drupal.getResourceCollection<DrupalNodeRelease[]>('node--release', {params: params.getQueryObject()});
}

async function getEarliestRelease(productId: string) {
  const params = new DrupalJsonApiParams({
    'filter[status]': 1,
    'sort': 'field_release_date',
  })
  params.addPageLimit(1)
  const firstReleases = await drupal.getResourceCollection<DrupalNodeRelease[]>('node--release', {params: params.getQueryObject()})
  return firstReleases[0]
}
'use client'
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import '@/styles/widget/faq.css'
import classNames from "classnames";

export const Faq = ({faqs, className}: {
  className?: string
  faqs: {
    question: string | JSX.Element | JSX.Element[],
    answer: string | JSX.Element | JSX.Element[],
  }[]
}) => {

  return <div className={classNames("widget-faq", className)}>
    {faqs.map((faq, index) => (
      <Disclosure key={index}>
        {({ open }) => (
          <>
            <Disclosure.Button
              // as="h3"
              className="cursor-pointer widget-faq-q text-left"
            >
              <span>{faq.question}</span>
              <ChevronUpIcon
                  className={`${
                    !open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-blue-900`}
                />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition-all overflow-hidden duration-500 ease-out"
              enterFrom="transform max-h-0 opacity-0"
              enterTo="transform max-h-full opacity-100"
              leave="transition-all overflow-hidden duration-500 ease-out"
              leaveFrom="transform max-h-full opacity-100"
              leaveTo="transform max-h-0 opacity-0"
            >
              <Disclosure.Panel 
                unmount={false}
                className="widget-faq-a"
              >
                {faq.answer}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    ))}
  </div>
}
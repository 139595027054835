'use client'
import { Fragment, ReactNode, useTransition } from "react"
import { Listbox } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { getDatePresents } from "./ReleaseNotes"
import { useQueryStates } from 'nuqs'
import { searchParamsReleaseNotes } from "@/lib/searchParams"
import { LoadingCover } from "./LoadingCover.client"
import { DrupalTaxonomyTerm } from "@/types"

export const ReleaseNotesFilters = ({ components, ranges, children }: {
  components: DrupalTaxonomyTerm[]
  ranges: ReturnType<typeof getDatePresents>["ranges"]
  children: ReactNode
}) => {
  const [isLoading, startTransition] = useTransition()
  const [params, setParams] = useQueryStates(searchParamsReleaseNotes, { startTransition })

  const range = ranges[params.rnDate as keyof typeof ranges]

  const component = components.find(c => c.id == params.rnComponent)

  return <>
    <h4>Filters</h4>
    <div className="bg-white border border-gray-400 rounded-lg p-4">
      <div className="flex gap-4">
        <label className="flex gap-2 items-center">
          <span className="font-medium">Date range</span>
          <div>
            <Listbox onChange={(e) => setParams({ ...params, rnDate: e }, { shallow: false })}>
              <Listbox.Button className='p-2 border border-gray-600 rounded flex gap-2 items-center'>{range.label} <ChevronDownIcon className="w-4" aria-hidden="true" /></Listbox.Button>
              <Listbox.Options className='relative' as='div'>
                <div className="absolute bg-white p-2 rounded shadow">
                {Object.keys(ranges).map(r => (
                  <Listbox.Option as={Fragment} key={r} value={r}
                    >
                      {({ active, selected }) => (
                        <div
                          className={`${
                            active ? 'bg-gray-400' : ''
                          } whitespace-nowrap p-2 hover:bg-gray-400 hover:cursor-pointer ${
                            selected ? 'bg-yellow-400' : ''
                          }`}
                        >
                          {ranges[r as keyof typeof ranges].label}
                        </div>
                      )}
                    </Listbox.Option>
                ))}
                </div>
              </Listbox.Options>
            </Listbox>
          </div>
        </label>

        <label className="flex gap-2 items-center">
          <span className="font-medium">Component</span>
          <div>
            <Listbox onChange={(e) => setParams({ ...params, rnComponent: e }, { shallow: false })}>
              <Listbox.Button className='p-2 border border-gray-600 rounded flex gap-2 items-center'>{component?.name ?? 'All'} <ChevronDownIcon className="w-4" aria-hidden="true" /></Listbox.Button>
              <Listbox.Options className='relative' as='div'>
                <div className="absolute bg-white p-2 rounded shadow">
                <Listbox.Option as={Fragment} value={''}>
                {({ active, selected }) => (
                  <div
                    className={`${
                      active ? 'bg-gray-400' : ''
                    } whitespace-nowrap p-2 hover:bg-gray-400 hover:cursor-pointer ${
                      selected ? 'bg-yellow-400' : ''
                    }`}
                  >
                    All
                  </div>
                  )}
                </Listbox.Option>
                {components.map(r => (
                  <Listbox.Option as={Fragment} key={r.id} value={r.id}
                    >
                      {({ active, selected }) => (
                        <div
                          className={`${
                            active ? 'bg-gray-400' : ''
                          } whitespace-nowrap p-2 hover:bg-gray-400 hover:cursor-pointer ${
                            selected ? 'bg-yellow-400' : ''
                          }`}
                        >
                          {r.name}
                        </div>
                      )}
                    </Listbox.Option>
                ))}
                </div>
              </Listbox.Options>
            </Listbox>
          </div>
        </label>
      </div>
    </div>
    <LoadingCover message='Loading release notes...' isLoading={isLoading}>{children}</LoadingCover>
  </>
}

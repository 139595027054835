'use client'
import { ReactNode, useEffect, useState } from "react";

export const ScrollToHash = ({ children }: {
  children: ReactNode
}) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const scrollToHash = (hash:string) => {
    // For scrolling to a particular section in a page
    document.querySelector(hash)?.scrollIntoView({behavior:"smooth"});
  }

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      scrollToHash(hash)
    }
  }, [isPageLoaded])

  useEffect(() => {
    setIsPageLoaded(true);
  }, [])

  return <>{children}</>
}
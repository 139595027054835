import { PreviewBarContextProps } from "@/contexts/PreviewBarProvider"
import {
  createSearchParamsCache,
  parseAsArrayOf,
  parseAsString,
  parseAsStringEnum
} from 'nuqs/server'
// Note: import from 'nuqs/server' to avoid the "use client" directive

export const searchParamsVersionedContent = {
  // Version support
  v: parseAsString,
}

export const searchParamsSidebarType = {
  draw: parseAsStringEnum(['search', 'chat'])
}

export const searchParamsPreviewMode = {
  // Preview mode
  previewMode: parseAsStringEnum<NonNullable<PreviewBarContextProps["preview"]["mode"]>>(['current', 'diff', 'revision']),
}

export const searchParamsReleaseNotes = {
  // Release Notes date
  rnDate: parseAsString.withDefault('recent'),
  // Release Notes component
  rnComponent: parseAsString
}

export const searchParamsSearch = {
  searchQuery: parseAsString,
  searchFiltersProducts: parseAsArrayOf(parseAsString),
  searchReferrer: parseAsStringEnum(['question', 'searchInput'])
}

export const searchParamsCache = createSearchParamsCache({
  ...searchParamsVersionedContent,
  ...searchParamsPreviewMode,
  ...searchParamsReleaseNotes,
  ...searchParamsSidebarType
})
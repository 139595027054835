'use client'

import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css';

import bash from 'highlight.js/lib/languages/bash';
import css from 'highlight.js/lib/languages/css';
import diff from 'highlight.js/lib/languages/diff';
import json from 'highlight.js/lib/languages/json';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import typescript from 'highlight.js/lib/languages/typescript';
import xml from 'highlight.js/lib/languages/xml';
import yaml from 'highlight.js/lib/languages/yaml';
import plaintext from 'highlight.js/lib/languages/plaintext';
import React, { ReactNode, useEffect, useId, useState } from 'react';
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames';
import { ClipboardDocumentCheckIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

export const highlightLanguages = {
    bash, css, diff, json, javascript, php, python, ruby, xml, yaml, typescript, 
    html: xml,
    plaintext
}

export interface CodeHighlightProps {
  language?: keyof typeof highlightLanguages
  children: ReactNode
  className?: string
}

export const CodeHighlight = ({children, language, className}: CodeHighlightProps) => {
  const id = useId()
  const [copied, setCopied] = useState(false)
  if (language != undefined && highlightLanguages[language] != undefined) {
    hljs.registerLanguage(language, highlightLanguages[language]);
  }
  useEffect(() => {
    const element = document.getElementById(id)
    if (!element || language === undefined) {
      return;
    }
    element.innerHTML = hljs.highlight(element.innerText, {language}).value
  }, [id, language])

  function copyToClipboard() {
      navigator.clipboard.writeText(decodeHTMLEntities(ReactDOMServer.renderToString(<>{children}</>)))
      .then(() => {
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 3000)
      })
      .catch(err => {
        setCopied(false)
        console.error(err);
      });
  }

  function decodeHTMLEntities(html:string) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
  }

  return <>
    <div className='my-2'>    
      <div className='flex gap-1 flex-row-reverse'>
        {/* <label>{language}</label> */}
        <button role="button" onClick={copyToClipboard} className={classNames('flex gap-1 items-center text-xs p-1', copied ? 'text-teal-800' : '')} title='Copy to clipboard'>
          {copied ? (
            <><ClipboardDocumentCheckIcon className='w-4' />copied!</>
          ) : (
            <><ClipboardDocumentListIcon className='w-4' />copy</>
          )}
        </button>
      </div>
      <pre>
        <code id={id} title={language} className={classNames(
          'rounded',
          `hljs ${language}`, 
          className
        )} data-lang={language}>
            {children}
        </code>
      </pre>
    </div>
  </>
}
  
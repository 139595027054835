'use client'
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { DetailedHTMLProps, Fragment, ImgHTMLAttributes, useState } from "react";

function isAcquiaDamAsset(url: string): boolean {
  return url.indexOf('acquia.widen.net') !== -1;
}


export const ModalImage = ({...props}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const [isOpen, setIsOpen] = useState(false)

  if (props.src == undefined) {
    return <></>
  }

  if (!isAcquiaDamAsset(props.src ?? '')) {
    if (!props.alt) {
      console.warn("Image has no alt tag: ", props)
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...props} />
  }

  const url = new URL(props.src as string)
  
  if (url.searchParams.has('w')) {
    url.searchParams.delete('w')
  }
  if (url.searchParams.has('h')) {
    url.searchParams.delete('h')
  }
  const full_url = url.toString()

  if (typeof props.width == "string") {
    props.width = parseInt(props.width)
  }
  if (typeof props.height == "string") {
    props.height = parseInt(props.height)
  }

  return (
    <>
      <Transition
        show={isOpen}
        enter="transition duration-500 ease-in"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition duration-300 ease-out"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
        as={Fragment}
      >
        <Dialog onClose={() => setIsOpen(false)}  as="div" className="fixed w-screen h-screen top-0 z-10 bg-navy-600/75 p-5">
          <Dialog.Panel className="w-fit mx-auto">
            <Dialog.Title className="text-white">{props.alt}</Dialog.Title>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img 
              {...props} 
              src={full_url} 
              width={undefined} 
              height={undefined} 
              className={classNames("object-scale-down max-h-[90vh] max-w-full", props.className)} />
          </Dialog.Panel>
        </Dialog>
      </Transition>
      
      <button role="button" onClick={() => setIsOpen(true)} title="Click to enlarge">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img {...props} />
      </button>
    </>
  )
}